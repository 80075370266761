import React from "react";
import { FaLinkedin, FaGithub } from "react-icons/fa";
import { HashLink as Link } from "react-router-hash-link";
import { BrowserRouter } from "react-router-dom";

const Header = () => {
  return (
    <BrowserRouter>
      <nav
        id="top"
        className="lg:mx-36 bg-[#232323] border-gray-200 px-2 sm:px-4 rounded py-5"
      >
        <div className="container flex flex-wrap items-center justify-around mx-auto">
          <div className="flex md:order-2 space-x-4 text-gray-600">
            <FaLinkedin
              className="text-xl curson-pointer hover:text-white transition ease-in-out"
              onClick={() =>
                window.open(
                  "https://www.linkedin.com/in/niall-grainger-aa968a147/",
                  "_blank"
                )
              }
            />
            <FaGithub
              className="text-xl curson-pointer  hover:text-white transition ease-in-out"
              onClick={() =>
                window.open("https://github.com/NiallGr", "_blank")
              }
            />
          </div>
          <div
            className="items-center justify-between hidden w-full md:flex md:w-auto md:order-1"
            id="mobile-menu-4"
          >
            <ul className="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium">
              <li></li>
              <Link
                to="#home"
                smooth
                className="block cursor-pointer text-[1rem] py-2 pl-3 pr-4 text-gray-400  rounded md:bg-transparent hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-100 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent transition ease-in-out dark:border-gray-700"
              >
                Home
              </Link>

              <li>
                <Link
                  to="#timeline"
                  smooth
                  className="block cursor-pointer text-[1rem] py-2 pl-3 pr-4 text-gray-400 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-100 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent transition ease-in-out dark:border-gray-700"
                >
                  Timeline
                </Link>
              </li>
              <li>
                <Link
                  to="#projects"
                  smooth
                  className="block cursor-pointer text-[1rem] py-2 pl-3 pr-4 text-gray-400 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-100 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent transition ease-in-out dark:border-gray-700"
                >
                  Projects
                </Link>
              </li>
              <li>
                <Link
                  to="#contact"
                  smooth
                  className="block cursor-pointer text-[1rem] py-2 pl-3 pr-4 text-gray-400 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-gray-100 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent transition ease-in-out dark:border-gray-700"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </BrowserRouter>
  );
};

export default Header;
