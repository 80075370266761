import React from "react";

const Timeline = () => {
  return (
    <div class="container bg-gray-200 mx-auto w-full h-full cursor-default">
      <div class="relative wrap overflow-hidden p-10 h-full">
        <div class="border-2-2 absolute border-opacity-20 left-1/2 border-gray-700 h-full border"></div>

        <div class="mb-8 flex justify-between items-center w-full right-timeline group">
          <div class="order-1 w-5/12"></div>
          <div class="z-20 flex items-center order-1 bg-[#080808] shadow-xl w-12 h-12 rounded-full group-hover:scale-125 transition duration-500 ease-in-out group-hover:bg-slate-500">
            <h1 class="mx-auto font-semibold text-lg text-white">2020</h1>
          </div>
          <div class="order-1 bg-white rounded-lg shadow-xl w-5/12 px-6 py-4 group-hover:scale-110 transition duration-500 ease-in-out">
            <h3 class="mb-3 font-bold text-gray-800 text-xl">
              Began My Journey
            </h3>
            <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
              My journey began in early 2020 when a friend introduced me to
              basic web development. I was hooked and started staying up late
              into the night, experimenting with different languages and
              frameworks. At this point, I knew that a career in tech was a
              future I wanted to be a part of.
            </p>
          </div>
        </div>

        <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline group">
          <div class="order-1 w-5/12"></div>
          <div class="z-20 flex items-center order-1 bg-[#080808] shadow-xl w-12 h-12 rounded-full group-hover:scale-125 transition duration-500 ease-in-out group-hover:bg-slate-500">
            <h1 class="mx-auto text-white font-semibold text-lg">2020</h1>
          </div>
          <div class="order-1 bg-white rounded-lg shadow-xl w-5/12 px-6 py-4 group-hover:scale-110 transition duration-500 ease-in-out">
            <h3 class="mb-3 font-bold text-gray-800 text-xl">
              Further Education
            </h3>
            <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
              In late 2020, I started a Postgraduate Certification Program in
              Full Stack Web Development from the California Institute of
              Technology. The program lasted from August 2020 to July 2021.
            </p>
          </div>
        </div>

        <div class="mb-8 flex justify-between items-center w-full right-timeline group">
          <div class="order-1 w-5/12"></div>
          <div class="z-20 flex items-center order-1 bg-[#080808] shadow-xl w-12 h-12 rounded-full group-hover:scale-125 transition duration-500 ease-in-out group-hover:bg-slate-500">
            <h1 class="mx-auto font-semibold text-lg text-white">2021</h1>
          </div>
          <div class="order-1 bg-white rounded-lg shadow-xl w-5/12 px-6 py-4 group-hover:scale-110 transition duration-500 ease-in-out">
            <h3 class="mb-3 font-bold text-gray-800 text-xl">
              Continue To Develop Skills
            </h3>
            <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100">
              After completing my program, I started exploring other areas of
              tech that piqued my interest. I enrolled in Udemy courses in
              mobile application development and also developed a pre-alpha
              version of a virtual reality coaching service in Unity.
            </p>
          </div>
        </div>

        <div class="mb-8 flex justify-between flex-row-reverse items-center w-full left-timeline group ">
          <div class="order-1 w-5/12"></div>
          <div class="z-20 flex items-center order-1 bg-[#080808] shadow-xl w-20 h-20 rounded-full group-hover:scale-125 transition duration-450 ease-in-out group-hover:bg-slate-500">
            <h1 class="mx-auto text-white font-semibold text-lg">
              2022-<br></br>2023
            </h1>
          </div>
          <div class="order-1 bg-white rounded-lg shadow-xl w-5/12 px-6 py-4 group-hover:scale-110 transition duration-450 ease-in-out">
            <h3 class="mb-3 font-bold text-gray-800 text-xl ">
              Relocate and Gained Employment
            </h3>
            <p class="text-sm leading-snug tracking-wide text-gray-900 text-opacity-100 ">
              After several years of working and studying abroad, I returned
              closer to home and put all my efforts into beginning my career in
              tech. Since then, I have joined a fantastic team as a junior
              software developer and have since been promoted past the junior
              level. I look forward to developing my skill set further and
              working on continuously more challenging projects. Thanks for
              reading!
            </p>
          </div>
        </div>
      </div>
      {/* <div class=" flex justify-center items-center w-full right-timeline">
        <div class=" flex items-center  bg-[#080808] shadow-xl w-20 h-10 mb-5 rounded-lg">
          <h1 class="mx-auto font-semibold text-lg text-white">Projects</h1>
        </div>
      </div> */}
    </div>
  );
};

export default Timeline;
