import React from "react";
import ProjectButtons from "./ProjectButtons";

const Projects = ({
  flip,
  VideoSrc,
  ProjectCustomOnPressUrl,
  projectTitle,
  hideButton,
  projectButtonText,
  buttonbackgroundColor,
  projectText,
}) => {
  return (
    <div
      class={`flex text-gray-600 body-font md:px-32 lg: flex${
        flip && "flex-row-reverse"
      }`}
    >
      <div class="container mx-auto flex px-5  py-[2rem] md:flex-row flex-col items-center">
        <div class="lg:flex-grow sm:w-1/2 md:w-1/10 lg:pr-12 md:pr-12 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
            {projectTitle}
          </h1>
          <p class="mb-8 leading-relaxed ">{projectText}</p>
          <div className="flex justify-center">
            <ProjectButtons
              CustomOnPressUrl={`${ProjectCustomOnPressUrl}`}
              text={`${projectButtonText}`}
              className={`px-8 w-fit py-2 shadow-sm shadow-[#363540] rounded-3xl bg-${buttonbackgroundColor}  ${hideButton} mb-6`}
            />
          </div>
        </div>
        <div class="lg:max-w-lg lg:w-full md:w-1/2 w-5/6 aspect-w-10 aspect-h-4">
          <iframe
            title="Video"
            className="rounded-lg "
            src={`${VideoSrc}`}
            frameborder="0"
            allow="accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Projects;
