import React from "react";
import Stack from "./Stack";
import Fade from "react-awesome-reveal";
import profile from "../images/profile.jpg";
import { HashLink as Link } from "react-router-hash-link";
import { BrowserRouter } from "react-router-dom";
import {
  FaReact,
  FaJava,
  FaAws,
  FaAngular,
  FaHtml5,
  FaCss3Alt,
  FaGithub,
} from "react-icons/fa";
import {
  SiJavascript,
  SiTailwindcss,
  SiMysql,
  SiSpringboot,
  SiPython,
  SiCsharp,
  SiNetapp,
  SiMicrobit,
  SiMicrosoftazure,
  SiMicrosoft,
  SiDotnet,
} from "react-icons/si";

const Banner = () => {
  return (
    <BrowserRouter>
      <div id="home" className="lg:mx-36 mx-6">
        <div className="md:flex items-center justify-between py-16">
          <div>
            <Fade triggerOnce={true} delay={1e3}>
              <p className="text-white text-[1.1rem] font-medium text-center md:text-start ">
                Hey there 👋 I am
              </p>
              <h1 className="text-white text-[2rem] md:text-[4rem] font-medium text-center md:text-start ">
                Niall Grainger
              </h1>
            </Fade>

            <Fade triggerOnce={true}>
              <p className="text-[#9C9C9C] text-center md:text-left  md:w-[28rem] mt-4 text-lg">
                Software Developer
              </p>
              <p className="text-[#9C9C9C] text-center md:text-left md:w-[28rem] mt-4 text-lg ">
                Developer and lifelong tech enthusiast. <br />
                Post-graduate Certification in Full-Stack Web development.{" "}
                <br />
              </p>
            </Fade>

            {/* JUMP TO PROJECTS SECTION */}
            <div className="flex justify-center md:justify-start">
              <Link
                to="#projects"
                smooth
                className={`w-3/5 text-center transition ease-in-out delay-150hover:-translate-y-1 hover:scale-110 duration-300 flex  text-white items-center font-bold py-4 px-16 mt-6 rounded-lg shadow-md shadow-[#0a016e] bg-gradient-to-r hover:bg-gradient-to-l from-[#0a016e] to-[#0e028e]`}
              >
                View My Projects
              </Link>
            </div>
          </div>

          <Fade triggerOnce={true}>
            <div className="flex items-center justify-center">
              <div
                className="xl:h-[25rem] h-[20rem] w-[20rem] xl:w-[25rem] rounded-full bg-cover mt-14 md:mt-0"
                style={{
                  backgroundImage: `url(${profile})`,
                }}
              ></div>
            </div>
          </Fade>
        </div>
        <Fade triggerOnce={true}>
          <div className="pb-16 flex flex-col items-center md:items-start">
            <p className="text-white">Worked With</p>

            <div className="flex flex-wrap justify-center md:justify-around">
              <Stack
                Icon={SiCsharp}
                name={"C#"}
                AddedStyles={`hover:border-[#671473]`}
                GroupAddedStyle={`group-hover:text-[#671473]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={SiJavascript}
                name={"Javascript"}
                AddedStyles={`hover:border-[#F0DB4F]`}
                GroupAddedStyle={`group-hover:text-[#F0DB4F]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={FaJava}
                name={"Java"}
                AddedStyles={`hover:border-[#ED1D25]`}
                GroupAddedStyle={`group-hover:text-[#ED1D25]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={SiTailwindcss}
                name={"Tailwind"}
                AddedStyles={`hover:border-[#3bb2ac]`}
                GroupAddedStyle={`group-hover:text-[#3bb2ac]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={FaAws}
                name={"AWS"}
                AddedStyles={`hover:border-[#FF9900]`}
                GroupAddedStyle={`group-hover:text-[#FF9900] `}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={FaAngular}
                name={"Angular"}
                AddedStyles={`hover:border-[#B52E31]`}
                GroupAddedStyle={`group-hover:text-[#B52E31]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={SiMysql}
                name={"MySQL"}
                AddedStyles={`hover:border-[#00758F]`}
                GroupAddedStyle={`group-hover:text-[#00758F]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={SiDotnet}
                name={"ASP.NET"}
                AddedStyles={`hover:border-[#671473]`}
                GroupAddedStyle={`group-hover:text-[#671473]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={FaHtml5}
                name={"HTML"}
                AddedStyles={`hover:border-[#FF5733]`}
                GroupAddedStyle={`group-hover:text-[#FF5733]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={FaCss3Alt}
                name={"CSS"}
                AddedStyles={`hover:border-[#0f5298]`}
                GroupAddedStyle={`group-hover:text-[#0f5298]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={FaGithub}
                name={"GitHub"}
                AddedStyles={`hover:border-[#36454F]`}
                GroupAddedStyle={`group-hover:text-[#36454F]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
              <Stack
                Icon={SiPython}
                name={"Python"}
                AddedStyles={`hover:border-[#f5d44d]`}
                GroupAddedStyle={`group-hover:text-[#f5d44d]`}
                BounceEffect={`group-hover:scale-150 transition duration-300 ease-in-out`}
              />
            </div>
          </div>
        </Fade>
      </div>
    </BrowserRouter>
  );
};

export default Banner;
