import React from "react";
import Fade from "react-awesome-reveal";

const BreakpointBannerTimeline = (bpBannersUrl) => {
  return (
    <div
      className="bg-fixed xl:h-[15rem] h-[15rem] w-full bg-center bg-cover md:mt-0"
      style={{
        // backgroundImage: `url(https://d2slcw3kip6qmk.cloudfront.net/marketing/blog/2017Q2/project-planning-header@2x.png)`,
        // backgroundImage: `url(https://images.unsplash.com/photo-1432888498266-38ffec3eaf0a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1474&q=80)`,
        backgroundImage: `url(https://images.unsplash.com/photo-1584949091598-c31daaaa4aa9?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)`,
      }}
    >
      {" "}
      <Fade triggerOnce={true}>
        <h1
          id="timeline"
          className="text-white text-center flex flex-col items-center  pt-16 text-[3rem] font-medium"
        >
          Timeline
        </h1>
      </Fade>
    </div>
  );
};

export default BreakpointBannerTimeline;
