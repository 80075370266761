import React from "react";
import { HashLink as Link } from "react-router-hash-link";
import { BrowserRouter } from "react-router-dom";

const Footer = () => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Day of the week
  const d = new Date();
  let day = weekday[d.getDay()];

  return (
    <BrowserRouter>
      <div>
        <footer class="lg:mx-36 p-4 bg-[#232323] rounded shadow md:flex md:items-center md:justify-between md:p-6">
          <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400">
            © 2022{" "}
            <a href="" class="hover:underline">
              Niall Grainger
            </a>
          </span>

          <span class="text-sm text-gray-400 sm:text-center dark:text-gray-400">
            {" "}
            Have a great {day} 🙂
          </span>
          <ul class="flex flex-wrap items-center mt-3 text-sm text-gray-400 sm:mt-0">
            <li>
              <Link
                to="#top"
                smooth
                class="mr-4 text-gray-400 hover:underline md:mr-6 "
              >
                Top
              </Link>
            </li>
            <li>
              <Link
                to="#projects"
                smooth
                class="mr-4 text-gray-400 hover:underline md:mr-6"
              >
                Projects
              </Link>
            </li>

            <li>
              <Link to="#contact" smooth class=" text-gray-400 hover:underline">
                Contact
              </Link>
            </li>
          </ul>
        </footer>
      </div>
    </BrowserRouter>
  );
};

export default Footer;
