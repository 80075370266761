import Fade, { Slide } from "react-awesome-reveal";
import emailjs from "@emailjs/browser";
import React, { useEffect, useRef, useState } from "react";

const Contact = () => {
  const form = useRef();
  const [status, setStatus] = useState("");

  //Email Service
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_YOUR_SERVICE_ID,
        process.env.REACT_APP_YOUR_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_YOUR_PUBLIC_KEY
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        },
        setStatus("SUCCESS"),
        // Reset fields
        e.target.reset()
      );
    // console.log("form submitted ✅");
  };

  // Remove Success after 3 seconds
  useEffect(() => {
    if (status === "SUCCESS") {
      setTimeout(() => {
        setStatus("");
      }, 3000);
    }
  });

  return (
    <div className="flex flex-col items-center">
      <div className="text-center flex flex-col items-center pb-16 pt-12">
        <Slide direction="right" triggerOnce={true}>
          <h1 className="text-white text-[3rem] font-medium">Get In Touch</h1>
          {/* <p className="text-[#9C9C9C] md:w-[35rem] text-center mx-4 mt-4 ">
            Thanks for stopping by. If you have any questions, please feel free
            to reach out!
          </p> */}
        </Slide>
      </div>

      <form
        id="contact"
        className="flex flex-col mb-24 space-y-4 pb-6 shadow-md"
        ref={form}
        onSubmit={sendEmail}
      >
        <Slide direction="right" triggerOnce={true}>
          <label className="flex flex-col text-lg font-medium text-white">
            Name
            <input
              required
              type="text"
              placeholder="Please Enter Name"
              name="name"
              className="outline-none px-2 rounded-md py-1 w-[20rem] text-[1rem] font-normal bg-transparent border border-gray-400 "
            ></input>
          </label>
          <label className="flex flex-col text-lg font-medium text-white">
            Email
            <input
              required
              type="email"
              placeholder="Please Enter Email"
              name="user_email"
              className="outline-none px-2 rounded-md py-1 w-[20rem] text-[1rem] font-normal bg-transparent border border-gray-400 "
            ></input>
          </label>
          <label className="flex flex-col  text-lg font-medium text-white">
            Message
            <textarea
              required
              name="message"
              placeholder="Please Enter Message"
              className="outline-none px-2 rounded-md py-1 w-[20rem] text-[1rem] font-normal bg-transparent border border-gray-400 "
            ></textarea>
          </label>
          <div className="flex items-center justify-center">
            <input
              text="submit"
              type="submit"
              Value="Send"
              className=" mt-6 px-10 w-fit py-2 border rounded-md border-gray-400 text-white hover:bg-white hover:text-black "
            />
          </div>
        </Slide>
        {/* Succesful form sent */}
        {status && renderAlert()}
      </form>
    </div>
  );
};

const renderAlert = () => (
  <div className="flex text-center justify-center text-white bg-black rounded">
    <p>✅ Your Message submitted Successfully ✅</p>
  </div>
);

export default Contact;
