import React, { forwardRef } from "react";
import Projects from "./Projects";
import Fade from "react-awesome-reveal";
import Stack from "./Stack";
import {
  FaReact,
  FaJava,
  FaAws,
  FaAngular,
  FaHtml5,
  FaCss3Alt,
  FaGithub,
} from "react-icons/fa";
import {
  SiJavascript,
  SiTailwindcss,
  SiMysql,
  SiSpringboot,
  SiUnity,
  SiCsharp,
  SiExpo,
  SiOculus,
  SiTypescript,
} from "react-icons/si";

const ProjectTabs = forwardRef((color) => {
  const [openTab, setOpenTab] = React.useState(4);
  return (
    <>
      <div
        id="projects"
        className={
          "flex flex-wrap bg-gray-200 " +
          (openTab === 1
            ? " bg-gray-200 transition ease-in-out duration-1000"
            : "" || openTab === 2
            ? " bg-[#00636f] transition ease-in-out duration-1000"
            : "" || openTab === 3
            ? " bg-[#157a4e] transition ease-in-out duration-1000"
            : "" || openTab === 4
            ? " bg-[#ae9e7f] transition ease-in-out duration-1000"
            : "" || openTab === 5
            ? " bg-[#06aef0] transition ease-in-out duration-1000"
            : "")
        }
      >
        <div
          // Remove [#] to remove BG color
          className={
            "lg:mx-36 mb-12 bg-gray-200  pt-[4rem]" +
            (openTab === 1
              ? " bg-gray-200 transition ease-in-out duration-1000"
              : "" || openTab === 2
              ? " bg-[#00636f]  transition ease-in-out duration-1000 "
              : "" || openTab === 3
              ? " bg-[#157a4e] transition ease-in-out duration-1000"
              : "" || openTab === 4
              ? " bg-[#ae9e7f] transition ease-in-out duration-1000"
              : "" || openTab === 5
              ? " bg-[#06aef0] transition ease-in-out duration-1000"
              : "")
          }
        >
          <Fade triggerOnce={true}>
            <ul
              className=" flex mb-1 mx-2 sm:mx-0 list-none flex-wrap pt-3 pb-4 sm:flex-row flex-col"
              role="tablist"
            >
              {/* TAB 1 Uber Clone */}
              <li className="mb-2 sm:mr-2 sm:last:mr-0  flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 1
                      ? "text-white  bg-gray-900"
                      : "text-" + color + "-600 bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(1);
                  }}
                  data-toggle="tab"
                  href="#link1"
                  role="tablist"
                >
                  Uber Clone
                </a>
                {/* TAB 2 Fly With Me */}
              </li>
              <li className="mb-2 sm:mr-2 sm:last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 2 ? "text-white bg-[#00636f] " : " bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(2);
                  }}
                  data-toggle="tab"
                  href="#link2"
                  role="tablist"
                >
                  Fly With Me
                </a>
              </li>
              {/* TAB 3 Dinner To Dine */}
              <li className="mb-2 sm:mr-2 sm:last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 3 ? "text-white bg-[#157a4e]" : " bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(3);
                  }}
                  data-toggle="tab"
                  href="#link3"
                  role="tablist"
                >
                  Dinner To Door
                </a>
              </li>
              {/* TAB 4 Fitness Visual */}
              <li className="mb-2 sm:mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 4
                      ? "text-white bg-[#ae9e7f]"
                      : "text-" + color + "-600 bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(4);
                  }}
                  data-toggle="tab"
                  href="#link4"
                  role="tablist"
                >
                  Fitness VR
                </a>
              </li>
              {/* TAB 5 Driving Theory QUIZ */}
              <li className="mb-2 mr-2 last:mr-0 flex-auto text-center">
                <a
                  className={
                    "text-xs font-bold uppercase px-5 py-3 shadow-lg rounded block leading-normal " +
                    (openTab === 5
                      ? "text-white bg-[#06aef0]"
                      : "text- bg-white")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    setOpenTab(5);
                  }}
                  data-toggle="tab"
                  href="#link5"
                  role="tablist"
                >
                  Driving Theory Quiz
                </a>
              </li>
            </ul>
          </Fade>
          {/* PROJECTS DISPLAY 1 - 5  */}
          <div className="relative flex flex-col min-w-0 break-words bg-white  w-full mb-6 shadow-lg rounded-lg">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                {/* PROJECT ONE Uber Clone*/}
                <div className={openTab === 1 ? "block" : "hidden"} id="link1">
                  <Fade triggerOnce={true}>
                    <Projects
                      class={`p-10`}
                      VideoSrc={`https://www.youtube.com/embed/ZtKXj1gQz8A`}
                      ProjectCustomOnPressUrl={`https://github.com/NiallGr/Uber_Clone`}
                      projectTitle={`Uber Clone`}
                      projectButtonText={`View Source Code`}
                      buttonbackgroundColor={`gray-900`}
                      projectText={`Initiative design, Users can search Current Location and desired destination, all presented on a visual map powered by google API.
                       Once the user can see their journey, they are presented with three tier format with each option having its very own estimated cost of the journey.`}
                    />
                    <div className="flex flex-wrap justify-center">
                      <Stack
                        Icon={FaReact}
                        name={"React JS"}
                        AddedStyles={`hover:border-[#61DBFB] h-[1rem] w-[7rem] `}
                        GroupAddedStyle={`group-hover:text-[#61DBFB] `}
                      />
                      <Stack
                        Icon={SiJavascript}
                        name={"Javascript"}
                        AddedStyles={`hover:border-[#F0DB4F] h-[1rem] w-[9rem]`}
                        GroupAddedStyle={`group-hover:text-[#F0DB4F]`}
                      />
                      <Stack
                        Icon={SiTailwindcss}
                        name={"Tailwind"}
                        AddedStyles={`hover:border-[#3bb2ac] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#3bb2ac]`}
                      />

                      <Stack
                        Icon={FaHtml5}
                        name={"HTML"}
                        AddedStyles={`hover:border-[#FF5733] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#FF5733]`}
                      />
                      <Stack
                        Icon={FaCss3Alt}
                        name={"CSS"}
                        AddedStyles={`hover:border-[#0f5298] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#0f5298]`}
                      />
                      <Stack
                        Icon={FaGithub}
                        name={"GitHub"}
                        AddedStyles={`hover:border-[#36454F] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#36454F]`}
                      />
                      <Stack
                        Icon={SiExpo}
                        name={"Expo"}
                        AddedStyles={`hover:border-[#00001f] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#00001f]`}
                      />
                    </div>
                  </Fade>
                </div>

                {/*  PROJECT 2 Fly with me*/}
                <div className={openTab === 2 ? "block " : "hidden"} id="link2">
                  <Fade triggerOnce={true}>
                    <Projects
                      VideoSrc={`https://www.youtube.com/embed/ROgk7AnqcxI`}
                      ProjectCustomOnPressUrl={`https://github.com/NiallGr/Fly_With_Me`}
                      projectTitle={`Fly With Me`}
                      projectButtonText={`View Source Code`}
                      buttonbackgroundColor={`[#00636f]`}
                      projectText={`flight booking application with a Sleek and simplistic UI.
                      Admin functionality includes Login & CRUD flight bookings, CRUD for
                      users accounts and administration staff. User functionality includes create & login user,
                      search flights, select and book.`}
                    />
                    <div className="flex flex-wrap justify-center">
                      <Stack
                        Icon={SiJavascript}
                        name={"Javascript"}
                        AddedStyles={`hover:border-[#F0DB4F] h-[1rem] w-[9rem]`}
                        GroupAddedStyle={`group-hover:text-[#F0DB4F] `}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={FaJava}
                        name={"Java"}
                        AddedStyles={`hover:border-[#ED1D25] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#ED1D25]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={SiMysql}
                        name={"MySQL"}
                        AddedStyles={`hover:border-[#00758F] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#00758F]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={SiSpringboot}
                        name={"SpringBoot"}
                        AddedStyles={`hover:border-[#6aad3d] h-[1rem] w-[9rem]`}
                        GroupAddedStyle={`group-hover:text-[#6aad3d]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={FaHtml5}
                        name={"HTML"}
                        AddedStyles={`hover:border-[#FF5733] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#FF5733]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={FaCss3Alt}
                        name={"CSS"}
                        AddedStyles={`hover:border-[#0f5298] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#0f5298]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={FaGithub}
                        name={"GitHub"}
                        AddedStyles={`hover:border-[#36454F] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#36454F]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                    </div>
                  </Fade>
                </div>

                {/* PROJECT 3  Dinner to Door*/}
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <Fade triggerOnce={true}>
                    <Projects
                      VideoSrc={`https://www.youtube.com/embed/15wBsJSrAkw`}
                      ProjectCustomOnPressUrl={`https://github.com/NiallGr/Angular-Frontend`}
                      projectTitle={`Dinner To Door`}
                      projectButtonText={`View Source Code`}
                      buttonbackgroundColor={`[#157a4e]`}
                      projectText={`Online food ordering service lets the user edit(CRUD) basket with an easy-to-use experience. 
                      Shipping and credit card
                      are requirement fields and an unique
                      order tracking number is presented to the User. Okta authenticated
                      admin login leads to intuitive CRUD functionality for all of the food items.`}
                    />
                    <div className="flex flex-wrap justify-center">
                      <Stack
                        Icon={SiJavascript}
                        name={"Javascript"}
                        AddedStyles={`hover:border-[#F0DB4F] h-[1rem] w-[9rem]`}
                        GroupAddedStyle={`group-hover:text-[#F0DB4F]`}
                      />
                      <Stack
                        Icon={SiTypescript}
                        name={"Typescript"}
                        AddedStyles={`hover:border-[#3178c6] h-[1rem] w-[9rem]`}
                        GroupAddedStyle={`group-hover:text-[#3178c6]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={FaJava}
                        name={"Java"}
                        AddedStyles={`hover:border-[#ED1D25] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#ED1D25]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />

                      <Stack
                        Icon={FaAngular}
                        name={"Angular"}
                        AddedStyles={`hover:border-[#B52E31] h-[1rem] w-[8rem]`}
                        GroupAddedStyle={`group-hover:text-[#B52E31]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={SiMysql}
                        name={"MySQL"}
                        AddedStyles={`hover:border-[#00758F] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#00758F]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={SiSpringboot}
                        name={"SpringBoot"}
                        AddedStyles={`hover:border-[#6aad3d] h-[1rem] w-[9rem]`}
                        GroupAddedStyle={`group-hover:text-[#6aad3d]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={FaHtml5}
                        name={"HTML"}
                        AddedStyles={`hover:border-[#FF5733] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#FF5733]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={FaCss3Alt}
                        name={"CSS"}
                        AddedStyles={`hover:border-[#0f5298] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#0f5298]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={FaGithub}
                        name={"GitHub"}
                        AddedStyles={`hover:border-[#36454F] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#36454F]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                    </div>
                  </Fade>
                </div>

                {/* {PROJECT 4 Vr Fitness} */}
                <div className={openTab === 4 ? "block" : "hidden"} id="link4">
                  <Fade triggerOnce={true}>
                    <Projects
                      VideoSrc={`https://www.youtube.com/embed/6V1B4hIgCTY`}
                      ProjectCustomOnPressUrl={`https://github.com/explore`}
                      projectTitle={`VR Fitness`}
                      hideButton={`invisible`}
                      projectText={`Virtual reality gym lets user interact with a coach who is
                       instructing a collection of exercise displayed on a
                      simple but effective UI. Users have the functionality to check their form in a
                      360° fashion, toggle displayed straight to the VR headset a unique feature not
                      easily replicated in a standard fitness practice.`}
                    />
                    <div className="flex flex-wrap justify-center">
                      <Stack
                        Icon={SiUnity}
                        name={"Unity"}
                        AddedStyles={`hover:border-[#7c7c7c] h-[1rem] w-[9rem]`}
                        GroupAddedStyle={`group-hover:text-[#7c7c7c]`}
                      />
                      <Stack
                        Icon={SiCsharp}
                        name={"C#"}
                        AddedStyles={`hover:border-[#95478e] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#95478e]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                      <Stack
                        Icon={SiOculus}
                        name={"Oculus"}
                        AddedStyles={`hover:border-[#00001f] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#00001f]`}
                        BounceEffect={`group-hover:animate-ping`}
                      />
                    </div>
                  </Fade>
                </div>
                {/* PROJECT 5  Driving Theory Quiz*/}
                <div className={openTab === 5 ? "block" : "hidden"} id="link5">
                  <Fade triggerOnce={true}>
                    <Projects
                      VideoSrc={`https://main.dm7amdyeszue9.amplifyapp.com/`}
                      ProjectCustomOnPressUrl={`https://main.dm7amdyeszue9.amplifyapp.com/`}
                      projectTitle={`Driving Theory Quiz`}
                      projectButtonText={`Deployed`}
                      buttonbackgroundColor={`[#06aef0]`}
                      projectText={`Let’s user complete a 25-question driving theory test where questions are tracked and visually displayed. 
                      At the end of the Quiz user's will be displayed a percentage of there result and an option 
                      to complete the quiz again in a completely new order.`}
                    />
                    <div className="flex flex-wrap justify-center">
                      <Stack
                        Icon={SiJavascript}
                        name={"Javascript"}
                        AddedStyles={`hover:border-[#F0DB4F] h-[1rem] w-[9rem]`}
                        GroupAddedStyle={`group-hover:text-[#F0DB4F]`}
                      />
                      <Stack
                        Icon={FaHtml5}
                        name={"HTML"}
                        AddedStyles={`hover:border-[#FF5733] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#FF5733]`}
                      />
                      <Stack
                        Icon={FaCss3Alt}
                        name={"CSS"}
                        AddedStyles={`hover:border-[#0f5298] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#0f5298]`}
                      />
                      <Stack
                        Icon={FaGithub}
                        name={"GitHub"}
                        AddedStyles={`hover:border-[#36454F] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#36454F]`}
                      />
                      <Stack
                        Icon={FaAws}
                        name={"AWS"}
                        AddedStyles={`hover:border-[#FF9900] h-[1rem] w-[7rem]`}
                        GroupAddedStyle={`group-hover:text-[#FF9900]`}
                      />
                    </div>
                  </Fade>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});

export default ProjectTabs;
