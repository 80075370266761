import Banner from "./components/Banner";
import BreakpointBannerTimeline from "./components/BreakpointBannerTimeline";
import BreakpointBannetContact from "./components/BreakpointBannetContact";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import Header from "./components/Header";
import ProjectsSection from "./components/ProjectsSection";
import ProjectTabs from "./components/ProjectTabs";

function App() {
  return (
    <div className="bg-[#080808] min-h-screen">
      <Header />
      <Banner />
      <BreakpointBannerTimeline />
      <ProjectsSection />
      <ProjectTabs />
      <BreakpointBannetContact />
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
