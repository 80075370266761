import React from "react";
import { Fade, Slide } from "react-awesome-reveal";
import Timeline from "./Timeline";

const ProjectsSection = () => {
  return (
    <div className="bg-gray-200 flex flex-col items-center">
      <div className="text-center flex flex-col items-center pt-12">
        {/* <Fade bottom>
          <h1 className="text-black text-[3rem] font-medium">Projects</h1>
        </Fade> */}
        <Slide direction={"right"} triggerOnce={true}>
          <div className="md:w-5/5 mt-4">
            {/* Timeline component */}
            <Timeline />
          </div>
        </Slide>
      </div>
    </div>
  );
};

export default ProjectsSection;
