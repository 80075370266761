import React from "react";

const Stack = ({ Icon, name, AddedStyles, GroupAddedStyle }) => {
  return (
    <div
      className={`border group border-[#9c9c9c] shadow-md curson-default rounded-md flex items-center w-[10rem] py-4 space-x-2 justify-center mr-4 my-4 ${AddedStyles}`}
    >
      <Icon
        className={`text-[#9c9c9c]  text-xl cursor-pointer group-hover:scale-150 transition duration-300 ease-in-out ${GroupAddedStyle}`}
      />
      <p
        className={`text-xl font-bold text-[#9c9c9c] cursor-default ${GroupAddedStyle}`}
      >
        {name}
      </p>
    </div>
  );
};

export default Stack;
