import React from "react";

const BreakpointBannetContact = () => {
  return (
    <div
      className="bg-fixed xl:h-[15rem] h-[15rem] w-full bg-center bg-cover md:mt-0"
      style={{
        backgroundImage: `url(https://images.unsplash.com/photo-1587560699334-cc4ff634909a?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80)`,
      }}
    ></div>
  );
};

export default BreakpointBannetContact;
