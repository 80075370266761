import React from "react";
import { BiChevronRight } from "react-icons/bi";

const ProjectButtons = ({ text, className, CustomOnPressUrl }) => {
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <button
      onClick={() => openInNewTab(`${CustomOnPressUrl}`)}
      className={`transition ease-in-out delay-150hover:-translate-y-1 hover:scale-110 duration-300 flex  text-white items-center font-bold py-4 px-16 mt-6 rounded-lg ${className}`}
    >
      {text} <BiChevronRight className='"ml-4' />
    </button>
  );
};

export default ProjectButtons;
